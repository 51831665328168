export enum CardType {
  EJECTION_RED = 'EJECTION_RED',
  RED = 'RED',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW'
}

export type CardFormData = {
  cardColor: string | null;
  playerNumber: string | null;
  powerPlay: boolean;
  minutes: string | null;
  tenSeconds: string | null;
  seconds: string | null;
};
