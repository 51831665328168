import {
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import {
  Http,
  queryParams,
} from '../../../../services/http';
import {
  endpoints,
  REFETCH_INTERVAL,
} from '../../../../utils/constants';
import { createMatchesMatrix } from './MatchesInfo.utils';
import { API } from '../../../../../types';

const matchesKeys = {
  matchesPDF: ['matches-pdf'] as const,
  byDay: (day: number) => ['matches', day] as const,
};

function fetchMatches(day: number): Promise<API.InProgressMatch[]> {
  return new Http<API.InProgressMatch[]>(endpoints.matches.url + queryParams({ day })).get();
}

export function useMatchesQuery(day: number): UseQueryResult<{
  [key: string]: API.InProgressMatch[];
}, unknown> {
  return useQuery(matchesKeys.byDay(day), () => fetchMatches(day), {
    select: createMatchesMatrix,
    refetchInterval: REFETCH_INTERVAL,
  });
}

function fetchMatchesPDF(): Promise<Blob> {
  const pdfHeaders = new Headers();
  pdfHeaders.append('Accept', 'application/pdf');
  pdfHeaders.append('Content-Type', 'application/pdf');
  return new Http<Blob>(endpoints.matches.url, { headers: pdfHeaders }).readAsBlob().get();
}

type PDF = Blob;

export function useMatchesPDFQuery(downloadPDF: (blob: Blob) => void): UseQueryResult<PDF> {
  return useQuery(matchesKeys.matchesPDF, fetchMatchesPDF, {
    cacheTime: 0,
    enabled: false,
    onSuccess: downloadPDF,
  });
}

interface MaxDay {
  maxDay: number;
}

const dayKeys = {
  maxDay: ['max-day'] as const,
};

function fetchMaxDay(): Promise<MaxDay> {
  return new Http<MaxDay>(endpoints.maxDayMatches.url).get();
}

export function useMaxDayQuery(): UseQueryResult<{
  maxDay: number;
}, unknown> {
  return useQuery(dayKeys.maxDay, fetchMaxDay);
}
