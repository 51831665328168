import {
  QueryCache,
  QueryClient,
  QueryClientConfig,
} from '@tanstack/react-query';

import { AppRoutes } from '../../utils/constants';
import { browserHistory } from '../history';
import {
  APIError,
  HttpStatus,
} from '../http';

// amount of time data is still considered fresh after fetch (thus data will be taken from cache)
const QUERY_STALE_TIME = 30_000;

// global query error handler
const queryCache = new QueryCache({
  onError: (error) => {
    if (error instanceof APIError && error.status === HttpStatus.INTERNAL_SERVER_ERROR) {
      browserHistory.push(AppRoutes.internalServerError.path);
    }
  },
});

const queryConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: QUERY_STALE_TIME,
    },
  },
  queryCache,
};

export const queryClient = new QueryClient(queryConfig);
