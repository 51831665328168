import { ID } from '../../../../types';

// url: used full absolute route path
// path: used by react router dom path prop

const publicRoutes = {
  root: { url: '/', path: '' },
  login: { url: '/login', path: '/login' },
  notFound: { url: '/404', path: '*' },
  internalServerError: { url: '/500', path: '/500' },
} as const;

const adminRoutes = {
  url: '/admin',
  path: 'admin/*',
  teams: {
    url: '/admin/teams',
    path: 'teams',
    new: { url: '/admin/teams/new', path: 'teams/new' },
    edit: {
      url: '/admin/teams/edit/:id',
      path: 'teams/edit/:id',
      build: (id: ID): string => `/admin/teams/edit/${id}`,
    },
  },
  conflicts: { url: '/admin/conflicts', path: 'conflicts' },
  groups: { url: '/admin/groups', path: 'groups' },
  matches: { url: '/admin/matches', path: 'matches' },
  categories: { url: '/admin/categories', path: 'categories' },
  info: { url: '/admin/info', path: 'info' },
  ranking: { url: '/admin/ranking', path: 'ranking' },
  registration: { url: '/admin/registration', path: 'registration' },
} as const;

const pitchRoutes = {
  url: '/pitches',
  path: 'pitches/*',
  pitch: {
    url: '/pitches/:role',
    path: ':role',
    build: (role: string): string => `/pitches/${role}`,
    match: {
      url: '/pitches/:role/:id',
      path: ':role/:id',
      build: (role: string, id: ID): string => `/pitches/${role}/${id}`,
    },
  },
} as const;

const leaderRoutes = {
  url: '/team-leader',
  path: 'team-leader/*',
  info: { url: '/team-leader/info', path: 'info' },
  teams: {
    url: '/team-leader/teams',
    path: 'teams',
    edit: {
      url: '/team-leader/teams/edit/:id',
      path: 'teams/edit/:id',
      build: (id: ID): string => `/team-leader/teams/edit/${id}`,
    },
    new: { url: '/team-leader/teams/new', path: 'teams/new' },
    view: {
      url: '/team-leader/teams/view/:id',
      path: 'teams/view/:id',
      build: (id: ID): string => `/team-leader/teams/view/${id}`,
    },
  },
  matches: { url: '/team-leader/matches', path: 'matches' },
  notifications: { url: '/team-leader/notifications', path: 'notifications' },
} as const;

export const AppRoutes = {
  ...publicRoutes,
  admin: adminRoutes,
  pitches: pitchRoutes,
  leader: leaderRoutes,
} as const;
