import { useQueries, useQuery, UseQueryResult } from '@tanstack/react-query';

import { Http } from '../../../../services/http';
import { endpoints, REFETCH_INTERVAL } from '../../../../utils/constants';
import { API, ID } from '../../../../../types';

const groupsKeys = {
  all: ['groups'] as const,
  byId: (id: ID) => ['group', id] as const,
};

function fetchGroups() {
  return new Http<API.GroupDTO[]>(endpoints.groups.url).get();
}

function transformGroups(groups: API.GroupDTO[], categoryName: string | undefined) {
  const filteredGroups = groups.filter((group) => group.categoryName === categoryName);
  const sortedGroups = filteredGroups.sort((a, b) => {
    if (a.categoryName === b.categoryName) {
      return a.name.localeCompare(b.name);
    }
    return a.categoryName.localeCompare(b.categoryName);
  });
  return sortedGroups;
}

function fetchGroup(id: ID) {
  return new Http<API.GroupDataDTO>(endpoints.group.build(id)).get();
}

export function useGroupsQuery(
  categoryName: string | undefined,
): UseQueryResult<API.GroupDTO[], unknown> {
  return useQuery(groupsKeys.all, fetchGroups, {
    select: (groups) => transformGroups(groups, categoryName),
    refetchInterval: REFETCH_INTERVAL,
    enabled: Boolean(categoryName),
  });
}

export function useGroupsDetailsQuery(
  groups: API.GroupDTO[] | undefined,
): API.GroupDataDTO[] {
  const groupQueries = groups?.map(({ id }) => ({
    queryKey: groupsKeys.byId(id),
    queryFn: () => fetchGroup(id),
  })) || [];

  const results = useQueries({ queries: groupQueries });

  return results
    .filter((result) => result.data)
    .map((result) => result.data as API.GroupDataDTO)
    .filter(({ matchesDetails }) => matchesDetails.length);
}
