import {
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import { Http } from '../../../../services/http';
import {
  endpoints,
  REFETCH_INTERVAL,
} from '../../../../utils/constants';
import {
  API,
  ID,
} from '../../../../../types';

const rankingKeys = {
  byCategoryId: (categoryId: ID | undefined) => ['ranking', categoryId] as const,
};

function fetchRanking(categoryId: ID | undefined): Promise<API.Ranking[]> {
  return new Http<API.Ranking[]>(endpoints.ranking.build(categoryId)).get();
}

function sortRanking(ranking: API.Ranking[]): API.Ranking[] {
  return ranking.sort(({ placeInCategory: a }, { placeInCategory: b }) => a - b);
}

export function useRankingQuery(categoryId: ID | undefined): UseQueryResult<API.Ranking[], unknown> {
  return useQuery(rankingKeys.byCategoryId(categoryId), () => fetchRanking(categoryId), {
    select: sortRanking,
    enabled: Boolean(categoryId),
    refetchInterval: REFETCH_INTERVAL,
  });
}
