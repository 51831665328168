import { Player } from '../../../../../types';
import { TeamDTO } from '../../../../../types/apiTypes';
import { CAPTAIN_ROLE_ABBR, REFEREE_AND_CAPTAIN_ROLE_ABBR, REFEREE_ROLE_ABBR } from '../../../../utils/constants';
import { sortPlayersByNumber } from '../../../../utils/helpers/sortingFunctions';
import styles from './styles.module.scss';

const renderRoles = (player: Player) => {
  if (player.isCaptain && player.isReferee) {
    return REFEREE_AND_CAPTAIN_ROLE_ABBR;
  }
  if (player.isCaptain) {
    return CAPTAIN_ROLE_ABBR;
  }
  if (player.isReferee) {
    return REFEREE_ROLE_ABBR;
  }
  return '';
};

interface InfoProps {
  team: TeamDTO
}

export function TeamDetails({ team }: InfoProps) {
  const playersSorted = [...team.players].sort(sortPlayersByNumber);

  return (
    <div>
      {team
      && (
        <>
          <hr className={styles.separatorLine} />
          <div key={team.id}>
            <table>
              <tbody>
                <tr>
                  <th>Team name:</th>
                  <td>{team.teamName}</td>
                </tr>
                <tr>
                  <th>Team acronym:</th>
                  <td>{team.teamAcronym}</td>
                </tr>
                <tr>
                  <th>Team leader:</th>
                  <td>{team.teamLeader.name} {team.teamLeader.surname}</td>
                </tr>
              </tbody>
            </table>
            <hr className={styles.separatorLine} />
            <h1 className={styles.playersHeader}>Team players:</h1>
            <div className={styles.cards}>
              {playersSorted.map((player) => (
                <div key={player.id} className={styles.playerCard}>
                  <h1> {player.name} {player.surname}</h1>
                  <p>Number: {player.playerNumber}</p>
                  <p>Year of birth: {player.yearOfBirth}</p>
                  <p>{renderRoles(player)}</p>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
