export type FormReducerState<T> = {
  formControl: {
    currentStep: number;
    maxStep: number;
  };
  formData: T;
  formVerification: {
    verificationStatus: boolean;
    wasSubmitted: boolean;
    errors: string[];
    stepErrorStatus: (boolean | null)[];
  };
};

export enum FormActions {
  CHANGE_FORM_DATA = 'CHANGE_FORM_DATA',
  NEXT_STEP = 'NEXT_STEP',
  PREV_STEP = 'PREV_STEP',
  GO_TO_STEP = 'GO_TO_STEP',
  VERIFY_FORM = 'VERIFY_FORM',
}

export type FormReducerActions<T> =
  { type: FormActions.CHANGE_FORM_DATA, fieldName: keyof FormReducerState<T>['formData'], newValue: string | boolean, }
  | { type: FormActions.NEXT_STEP }
  | { type: FormActions.PREV_STEP }
  | { type: FormActions.GO_TO_STEP, step: number }
  | { type: FormActions.VERIFY_FORM, errors: string[], stepErrorStatus: (boolean | null)[] };
