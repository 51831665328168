import {
  useEffect,
  useState,
} from 'react';

export const useOnlineStatus = (): boolean => {
  const [isOnline, setIsOnline] = useState<boolean>(true);

  useEffect(() => {
    const onConnect = (): void => {
      setIsOnline(true);
    };

    const onDisconnect = (): void => {
      setIsOnline(false);
    };

    window.addEventListener('online', onConnect);
    window.addEventListener('offline', onDisconnect);

    return () => {
      window.removeEventListener('online', onConnect);
      window.removeEventListener('offline', onDisconnect);
    };
  });

  return isOnline;
};
