import { useState } from 'react';
import clsx from 'clsx';

import { matchesTableHead } from './MatchesInfo.utils';
import {
  useMatchesPDFQuery,
  useMatchesQuery,
  useMaxDayQuery,
} from './MatchesInfo.queries';
import { downloadFile } from '../../../../utils/helpers/utils';
import { InfoTableEmptyRow } from './InfoTableEmptyRow';
import { InfoTableRow } from './InfoTableRow';
import { Button } from '../../../../components/Button';
import { DayControl } from '../../../../components/DayControl';
import { ContentLoader } from '../../../../components/ContentLoader';
import styles from './styles.module.scss';

const INITIAL_DAY = 1;

export function MatchesInfo(): JSX.Element {
  const [currentDay, setCurrentDay] = useState(INITIAL_DAY);

  const maxDay = useMaxDayQuery();
  const matches = useMatchesQuery(currentDay);
  const matchesPDF = useMatchesPDFQuery(downloadFile);

  const incrementDay = (): void => {
    const maxDayLimit = maxDay.data?.maxDay ?? INITIAL_DAY;
    if (currentDay >= maxDayLimit) return;
    setCurrentDay((prev) => prev + 1);
  };

  const decrementDay = (): void => {
    const MIN_DAY = 1;
    if (currentDay === MIN_DAY) return;
    setCurrentDay((prev) => prev - 1);
  };

  const handleDownloadPDF = () => matchesPDF.refetch();

  return (
    <>
      <DayControl
        limit={maxDay.data?.maxDay ?? INITIAL_DAY}
        day={currentDay}
        decrementDay={decrementDay}
        incrementDay={incrementDay}
      />
      <ContentLoader
        isFetching={matches.isLoading}
        fetchingMessage="Loading matches data..."
        content={matches.data}
        emptyMessage="No matches to display"
      >
        <>
          <div className={styles.tableWrapper}>
            <table className={styles.table}>
              <thead>
                <tr>
                  {matchesTableHead.map((head) => (typeof head === 'string'
                    ? <th key={head}>{head}</th>
                    : <th key={head.val} colSpan={head.span}>{head.val}</th>))}
                </tr>
              </thead>
              <tbody>
                {matches.data && Object.entries(matches.data).map(([time, matchesInHour], i) => {
                  const matchesCount = matchesInHour.length;
                  const isOdd = i % 2;
                  const cellBackground = clsx({ [styles.greyoutRow]: isOdd });
                  return matchesCount
                    ? <InfoTableRow key={time} className={cellBackground} matches={matchesInHour} time={time} />
                    : <InfoTableEmptyRow key={time} className={cellBackground} time={time} />;
                })}
              </tbody>
            </table>
          </div>
          <Button className={styles.downloadPDF} onClick={handleDownloadPDF}>Match Schedule PDF</Button>
        </>
      </ContentLoader>
    </>
  );
}
