import React, { useEffect } from 'react';

import { GenericFunction } from '../../../types';

type Refs = React.MutableRefObject<any>[] | React.MutableRefObject<any>;

export function useDetectClickOutside(refs: Refs, callback: GenericFunction): void {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!Array.isArray(refs)) {
        if (!refs.current || refs.current.contains(e.target)) return;
      } else if (refs.some((ref) => !ref.current || ref.current.contains(e.target))) return;

      callback(e);
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [callback, refs]);
}
