import {
  KeycloakInstance,
  KeycloakTokenParsed,
} from 'keycloak-js';

import {
  UserRole,
  AppRoutes,
} from '../constants';
import {
  ArrayElement,
  EligibleReferee,
  GenericObject,
  ID,
  MatchPlayer,
  Player,
  Primitive,
  Referee,
  TeamLeader,
} from '../../../types';

export const minutesButtons = Array.from({ length: 10 }, (_, i) => i);
export const everyTenSecondButtons = ['00', '10', '20', '30', '40', '50'];
export const secondsButtons = Array.from({ length: 9 }, (_, i) => i + 1).concat(0);

/**
 * Deletes properties with NaNs and empty string values.
 *
 * @param obj - can any object
 * @returns object - cleaned object
 *
 */

export function cleanObject<T extends GenericObject>(obj: T): T {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => (typeof v === 'number' ? !Number.isNaN(v) : v !== '')),
  ) as T;
}

/**
 * Id properties created by frontend will have null value (ones that are generated by uuid)
 *
 *
 * @param data - array of objects with id property
 * @returns array - array with null id property
 *
 */
export function removeIdProp<T extends {id: ID | null}>({ id, ...rest }: T)
  : Pick<T, Exclude<keyof T, 'id'>> & {id: number | null} {
  return { ...rest, id: typeof id === 'string' ? null : id };
}

/**
 * Replace object in array at specified index
 *
 * @param array - with objects inside
 * @param index - replacement index
 * @param newObject - object that will replace the old one
 * @returns array - array with the same object but without id property
 *
 */
export function replaceObject<T extends GenericObject[]>(array: T, index: number, newObject: ArrayElement<T>): T {
  const arrCopy = array.slice(0) as T;
  arrCopy[index] = newObject;
  return arrCopy;
}

/**
 * Checks whether object is empty
 *
 * @param obj - object that will be examined
 * @returns boolean - returns true if object is empty
 *
 */
export function isEmpty<T extends GenericObject>(obj: T): boolean {
  return !Object.keys(obj).length;
}

/**
 * Function compares arrays and returns true if their content is identical
 *
 * @param arr1 - first array
 * @param arr2 - second array
 * @returns boolean - returns true if object is empty
 *
 */
export function isArrayIdentical(arr1: Primitive[], arr2: Primitive[]): boolean {
  return !arr1.some((val, i) => val !== arr2[i]);
}

/**
 * Function splits seconds and returns its first value multiplied by 10 and second value
 *
 * @param seconds - number of seconds
 *
 * @returns array - returns array with two values seconds values after split
 *
 */
export function splitSeconds(seconds: number): string[] {
  let splittedSeconds: string[] = [];

  if (seconds < 10) {
    splittedSeconds[0] = '00';
    splittedSeconds[1] = seconds.toString();
  } else {
    splittedSeconds = seconds.toString().split('');
    splittedSeconds[0] += '0';
  }

  return splittedSeconds;
}

/**
 * Function searches for player in array and returns his fullName
 *
 * @param playerNumber - unique player number
 * @param playersArray - players array
 *
 * @returns playerName - playerName or false when playerName cannot be returned
 *
 */
export function assembleFullPlayerName(playerNumber: string | null, playersArray: MatchPlayer[]): string | false {
  const playerData = playersArray.find((player) => player.playerNumber.toString() === playerNumber);
  if (!playerData?.playerName && !playerData?.playerSurname) return false;
  return `${playerNumber} - ${playerData.playerName} ${playerData.playerSurname}`;
}

/**
 * Function assembles time from seconds and minutes. Time returned in form - 9:52
 *
 * @param minutes - minutes
 * @param tenSeconds - first seconds digit
 * @param seconds - second seconds digit
 *
 * @returns time - time is returned time if it time can be assembled. Otherwise false is returned.
 *
 */
export function assembleFullTime(
  minutes: string | null,
  tenSeconds: string | null,
  seconds: string | null,
): string | false {
  if (!minutes || !tenSeconds || !seconds) return false;
  return `${minutes} : ${tenSeconds.slice(0, -1)}${seconds}`;
}

export function getPitchRole(keycloak: KeycloakInstance): string | null {
  const pitchRole = keycloak.tokenParsed?.realm_access?.roles[0];
  if (!pitchRole || !(new RegExp(UserRole.PITCH).test(pitchRole))) {
    return null;
  }
  return pitchRole;
}

/**
* Function returns correct path for user
*
* @param keycloak - keycloak instance
*
* @returns path - default usre path
*
*/
export function getUserDashboardPath(keycloak: KeycloakInstance): string {
  if (!keycloak.authenticated) return AppRoutes.root.path;
  if (keycloak.hasRealmRole(UserRole.ADMIN)) return AppRoutes.admin.url;
  if (keycloak.hasRealmRole(UserRole.TEAM_LEADER)) return AppRoutes.leader.url;
  const pitchRole = getPitchRole(keycloak);
  if (pitchRole) return AppRoutes.pitches.pitch.build(pitchRole);
  throw new Error('unhandled user role');
}

type Team = {
  players: Player[];
  referees: Referee[];
  teamLeader: Omit<TeamLeader, 'id'>;
}
/**
* Function returns list composed of referees, and players/leader with referee role
*
* @param team - team with players, referees and teamLeader
*
* @returns path - all people with referee status
*
*/
export function getAllRefereesList(team: Team | Record<string, never>): EligibleReferee[] {
  if (isEmpty(team)) return [];

  const referees = team.referees
    .map((referee) => ({ ...referee, type: 'referee' } as EligibleReferee));

  const players = team.players
    .filter(({ isReferee }) => isReferee)
    .map((player) => ({ ...player, type: 'player' } as EligibleReferee));

  const allReferees: EligibleReferee[] = [...referees, ...players];
  if (team.teamLeader.isReferee) {
    allReferees.push({ ...team.teamLeader, type: 'leader' } as EligibleReferee);
  }
  return allReferees;
}

/**
* Validate keycloak token
*
* @param token - received KeycloakToken
*
* @returns boolean - true if token is valid
*
*/
export function isAuthTokenValid(token: KeycloakTokenParsed | undefined): boolean {
  if (!token) {
    throw new Error('No keycloak token provided');
  }
  // assume that user can have only one role assigned to him
  if ((token.realm_access?.roles.length || 0) > 1) {
    return false;
  }
  if (!token.family_name || !token.given_name || !token.preferred_username) {
    return false;
  }
  const userIsTeamLeader = token.realm_access?.roles.includes(UserRole.TEAM_LEADER);
  if (userIsTeamLeader && (!token.email || !token.phone)) {
    return false;
  }
  return true;
}

/**
* Remove underscore from string
*
* @param token - string with underscore
*
* @returns string - without underscore
*
*/
export function removeUnderscore(string: string): string {
  return string.replaceAll('_', ' ');
}

/**
* Passed callback will be used to divide array into two
*
* @param array - any array that will be divided
* @param predicate - callback that returns boolean
*
* @returns [passedValues[], failedValues[]]
*
*/
export function partition<T>(array: T[], predicate: (val: T) => boolean): [T[], T[]] {
  const pass: T[] = [];
  const fail: T[] = [];
  array.forEach((value) => {
    (predicate(value) ? pass : fail).push(value);
  });
  return [pass, fail];
}

/**
 * Html node that react will use for rendering purposes
 * @returns html element
 */
export function getRootElement(): Element {
  return document.querySelector('#root') || document.body;
}

/**
 * Prepend single digit value with 0
 * @param timeValue digit to be formatted
 * @returns formatted time value
 */
export function formatTimeString(timeValue: number): string {
  return timeValue < 10 ? `0${timeValue}` : timeValue.toString();
}

/**
* Trim string and delete all repeating spaces
*/
export function allTrim(string: string): string {
  return string.trim().replace(/\s+/g, ' ');
}

/**
 * Download file without causing memory leak (revoking created object)
 * @param fileBlob downloaded blob
 */
export function downloadFile(fileBlob: Blob): void {
  const downloadUrl = URL.createObjectURL(new Blob([fileBlob]));
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', `MatchSchedule[${new Date().toLocaleString()}].pdf`);
  document.body.appendChild(link);
  link.click();
  link.remove();
  URL.revokeObjectURL(downloadUrl);
}
