import { Player } from '../../../types';

interface MatchDate {
  day: string;
  timeSlotHours: string;
  timeSlotMinutes: string;
}

export function sortByMatchDate(el1: MatchDate, el2: MatchDate): number {
  const el1Day = parseInt(el1.day, 10);
  const el1Time = parseInt(el1.timeSlotHours + el1.timeSlotMinutes, 10);

  const el2Day = parseInt(el2.day, 10);
  const el2Time = parseInt(el2.timeSlotHours + el2.timeSlotMinutes, 10);

  return el1Day - el2Day || el1Time - el2Time;
}

interface Time {
  minutes: number;
  seconds: number;
}

export function sortByTime(el1: Time, el2: Time): number {
  return el1.minutes - el2.minutes || el1.seconds - el2.seconds;
}

export function sortPlayersByNumber(a: Player, b: Player) {
  return a.playerNumber - b.playerNumber;
}
