import {
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import { Http } from '../../services/http';
import { endpoints } from '../constants';
import { API } from '../../../types';

const teamsKeys = {
  all: ['teams'] as const,
};

function fetchTeams() {
  return new Http<API.TeamDTO[]>(endpoints.teams.url).get();
}

export function useTeamsQuery(): UseQueryResult<API.TeamDTO[], unknown> {
  return useQuery(teamsKeys.all, fetchTeams);
}
