import { useKeycloak } from '../../../services/keycloak';
import { Button } from '../../Button';
import { Icon } from '../../Icon';

const loginPath = process.env.REACT_APP_LOGIN_URL;

export function LoginActions(): JSX.Element {
  const { keycloak } = useKeycloak();

  return (
    <div>
      <Button
        icon={{ position: 'beginning', src: <Icon iconName="door" direction="right" /> }}
        onClick={() => keycloak.login({ redirectUri: loginPath })}
      >
        LOGIN
      </Button>
    </div>
  );
}
