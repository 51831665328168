import {
  lazy,
  Suspense,
} from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';

import { AppRoutes } from '../../utils/constants';
import { RootRedirect } from './RootRedirect';
import { TournamentInfo as Home } from '../../views/public/TournamentInfo';
import { LoadingPage } from '../../views/public/LoadingPage';
import { InternalServerError } from '../../views/public/InternalServerError';
import { NotFound } from '../../views/public/NotFound';

const AdminViews = lazy(() => import('./Routes/AdminRoutes'));
const PitchViews = lazy(() => import('./Routes/PitchRoutes'));
const LeaderViews = lazy(() => import('./Routes/LeaderRoutes'));

interface AppRouterProps {
  layout: JSX.Element;
}

export function AppRouter({ layout }: AppRouterProps): JSX.Element {
  return (
    <Suspense fallback={<LoadingPage loadingText="Loading pages..." />}>
      <Routes>
        <Route element={layout}>
          <Route index element={<Home />} />
          <Route path={AppRoutes.admin.path} element={<AdminViews />} />
          <Route path={AppRoutes.pitches.path} element={<PitchViews />} />
          <Route path={AppRoutes.leader.path} element={<LeaderViews />} />
          <Route path={AppRoutes.login.path} element={<RootRedirect />} />
          <Route path={AppRoutes.internalServerError.path} element={<InternalServerError />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Suspense>
  );
}
