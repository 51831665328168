import { useRankingQuery } from './TeamsRanking.queries';
import { ContentLoader } from '../../../../components/ContentLoader';
import { ID } from '../../../../../types';
import styles from './styles.module.scss';

interface TeamsRankingProps {
  selectedCategoryId: ID | undefined;
}

export function TeamsRanking({ selectedCategoryId }: TeamsRankingProps): JSX.Element {
  const ranking = useRankingQuery(selectedCategoryId);

  return (
    <ContentLoader
      isFetching={ranking.isLoading}
      fetchingMessage="Loading teams data..."
      emptyMessage="No ranking data"
      content={ranking.data}
    >
      <table className={styles.table} cellSpacing={0}>
        <thead>
          <tr>
            <th>Place</th>
            <th>Team</th>
          </tr>
        </thead>
        <tbody>
          {ranking.data?.map(({ id, placeInCategory, teamName }) => (
            <tr key={id}>
              <td>{placeInCategory}</td>
              <td>{teamName}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ContentLoader>
  );
}
