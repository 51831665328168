import { useNavigate } from 'react-router-dom';

import { GenericButton } from './GenericButton';
import { Icon } from '../Icon';
import { ButtonProps } from '../../../types';

export function GoBackButton({ children, ...props }: ButtonProps): JSX.Element {
  const navigate = useNavigate();

  return (
    <GenericButton
      {...props}
      variant="outlined"
      theme="context"
      onClick={() => navigate(-1)}
      icon={{ position: 'beginning', src: <Icon iconName="arrow" /> }}
    >{children}
    </GenericButton>
  );
}
