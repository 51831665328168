import clsx from 'clsx';

import { useNotification } from '../../utils/context/NotificationContext';
import { Icon } from '../Icon';
import styles from './styles.module.scss';

export function Notification(): JSX.Element | null {
  const { notification, hide } = useNotification();

  const notificationStyle = clsx([styles.notification], [styles[notification.type]]);

  return notification.visible ? (
    <div role="button" tabIndex={0} className={notificationStyle} onClick={hide}>
      <div role="alert" className={styles.notificationText}>{notification.message}</div>
      <Icon iconName="closeMark" className={styles.closeText} />
    </div>
  ) : null;
}
