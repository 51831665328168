import { useNavigate } from 'react-router-dom';

import { GenericButton } from './GenericButton';
import { getUserDashboardPath } from '../../utils/helpers/utils';
import { ButtonProps } from '../../../types';
import styles from './styles.module.scss';
import { useKeycloak } from '../../services/keycloak';

export function ToDashboardButton({ children, ...props }: ButtonProps): JSX.Element {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  return (
    <GenericButton
      {...props}
      variant="outlined"
      theme="context"
      className={styles.dashboardButton}
      onClick={() => navigate(getUserDashboardPath(keycloak), { replace: true })}
    >
      {children}
    </GenericButton>
  );
}
