import { Button } from '../Button';
import { ButtonTheme, ID, Tab } from '../../../types';
import styles from './styles.module.scss';

interface TabsProps<T> {
  tabsList: Tab<T>[];
  theme: ButtonTheme;
  activeTab: T | null;
  onTabSelect(tab: Tab<T>): void;
}

export function Tabs<T extends ID>({
  activeTab, tabsList, theme, onTabSelect,
}: TabsProps<T>): JSX.Element {
  return (
    <div className={styles.tabs}>
      {tabsList.map((tab) => (
        <Button
          key={tab.label}
          className={styles.tab}
          variant="tab"
          theme={theme}
          selected={activeTab === tab.value}
          onClick={() => onTabSelect(tab)}
        >
          {tab.label}
        </Button>
      ))}
    </div>
  );
}
