import {
  API,
  MatchStatus,
} from '../../../../../types';

const TOURNAMENT_START_HOUR = 6;
const TOURNAMENT_END_HOUR = 21;

export const matchesTableHead = [
  '#',
  'TIME',
  'PITCH',
  'CATEGORY',
  'GROUP',
  'TEAM1',
  'TEAM2',
  { val: 'SCORE', span: 2 },
  'REFEREES',
  'REFEREE TABLE',
  'STATUS',
];

export const matchesTableColumnCount = matchesTableHead.reduce((columnSum, headElement) => {
  if (typeof headElement === 'string') return columnSum + 1;
  return columnSum + headElement.span;
}, 0);

export const allowedMatchStatusesMap: Readonly<Partial<Record<MatchStatus, string>>> = {
  CREATED: 'CREATED',
  FINISHED: 'FINISHED',
  IN_PROGRESS: 'IN PROGRESS',
  PENDING_APPROVAL: 'PENDING APPROVAL',
};

function createEmptyMatches(): { [key: string]: API.InProgressMatch[] } {
  const emptyMatches: { [key: string]: API.InProgressMatch[] } = {};

  for (let currentHour = TOURNAMENT_START_HOUR; currentHour < TOURNAMENT_END_HOUR; currentHour += 1) {
    emptyMatches[`${currentHour}:00`] = [];
    emptyMatches[`${currentHour}:30`] = [];
  }
  emptyMatches[`${TOURNAMENT_END_HOUR}:00`] = [];

  return emptyMatches;
}

function trimEmptyMatches(matches: { [key: string]: API.InProgressMatch[] })
: { [key: string]: API.InProgressMatch[] } {
  let firstMatchIndex = Infinity;
  let lastMatchIndex = Infinity;

  const matchesCount = Object.keys(matches).length;
  const matchesValues = Object.values(matches);

  const cleanedMatches = { ...matches };

  for (let i = 0; i < matchesCount; i += 1) {
    if (matchesValues[i].length) {
      firstMatchIndex = i;
      break;
    }
  }

  for (let i = matchesCount - 1; i >= 0; i -= 1) {
    if (matchesValues[i].length) {
      lastMatchIndex = i;
      break;
    }
  }

  if (firstMatchIndex === Infinity) return matches;

  Object.keys(cleanedMatches).forEach((k, i) => {
    if (i < firstMatchIndex || i > lastMatchIndex) {
      delete cleanedMatches[k];
    }
  });

  return cleanedMatches;
}

export function createMatchesMatrix(matchesList: API.InProgressMatch[]): {
  [key: string]: API.InProgressMatch[];
} {
  const matchesTableData = createEmptyMatches();
  matchesList.sort((match1, match2) => parseInt(match1.pitchNumber, 10) - parseInt(match2.pitchNumber, 10));
  matchesList.forEach((match) => matchesTableData[`${match.timeSlotHours}:${match.timeSlotMinutes}`].push(match));
  return trimEmptyMatches(matchesTableData);
}
