import { Notification } from '../../Notification';
import { TournamentInfo } from '../../../views/public/TournamentInfo';
import styles from './styles.module.scss';

export function NoAuthServiceMode(): JSX.Element {
  return (
    <>
      <div className={styles.noAuthBanner}>
        <h1>Authentication service is currently unavailable</h1>
      </div>
      <Notification />
      <main>
        <TournamentInfo />
      </main>
    </>
  );
}
