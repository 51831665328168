import { ID } from './id';
import { Player } from './player';
import { Referee } from './referee';
import { TeamLeader } from './teamLeader';

export type TeamRegistrationState = {
  teamDetails: {
    teamName: string;
    acronym: string;
    acronymModifier: string;
    category: string | null;
  }
  hasTeamCaptain: boolean;
  teamLeader: Omit<TeamLeader, 'id'>;
  players: Player[];
  referees: Referee[];
};

export enum FormType {
  CHANGE_TEAM_DETAILS = 'CHANGE_TEAM_DETAILS',
  DELETE_PLAYER = 'DELETE_PLAYER',
  DELETE_REFEREE = 'DELETE_REFEREE',
  CHANGE_MODAL = 'CHANGE_MODAL',
  EDIT_TEAM_LEADER = 'EDIT_TEAM_LEADER',
  ADD_PLAYER = 'ADD_PLAYER',
  ADD_REFEREE = 'ADD_REFEREE',
  CHANGE_CAPTAIN_STATUS = 'CHANGE_CAPTAIN_STATUS',
  EDIT_PLAYER = 'EDIT_PLAYER',
  EDIT_REFEREE = 'EDIT_REFEREE',
  DISABLE_PLAYER_AS_REFEREE = 'DISABLE_PLAYER_AS_REFEREE',
  CHANGE_LEADER_REFEREE_STATUS = 'CHANGE_LEADER_REFEREE_STATUS',
  SET_FORM_STATE = 'SET_FORM_STATE'
}

export type TeamRegistrationActions =
  | { type: FormType.CHANGE_TEAM_DETAILS, field: keyof TeamRegistrationState['teamDetails'], payload: string }
  | { type: FormType.DELETE_PLAYER, id: ID }
  | { type: FormType.DELETE_REFEREE, id: ID }
  | { type: FormType.EDIT_TEAM_LEADER, teamLeader: TeamRegistrationState['teamLeader'] }
  | { type: FormType.ADD_PLAYER, player: Player }
  | { type: FormType.ADD_REFEREE, referee: Referee }
  | { type: FormType.CHANGE_CAPTAIN_STATUS }
  | { type: FormType.EDIT_PLAYER, player: Player }
  | { type: FormType.EDIT_REFEREE, referee: Referee }
  | { type: FormType.DISABLE_PLAYER_AS_REFEREE, playerId: ID }
  | { type: FormType.CHANGE_LEADER_REFEREE_STATUS }
  | { type: FormType.SET_FORM_STATE, newState: TeamRegistrationState };
