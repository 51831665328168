import { Button } from '../../../components/Button';
import styles from './styles.module.scss';

interface ErrorPageProps {
  title: string | number;
  description?: string;
}

export function ErrorPage({ title, description }: ErrorPageProps): JSX.Element {
  return (
    <div className={styles.errorLayout}>
      <p className={styles.errorTitle}>{title}</p>
      <p className={styles.errorDescription}>{description}</p>
      <div className={styles.buttonsGap}>
        <Button.ToDashboard>Menu</Button.ToDashboard>
        <Button.GoBack>Return to previous page</Button.GoBack>
      </div>
    </div>
  );
}
