import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { NOTIFICATION_TIMEOUT } from '../constants';
import {
  Notification,
  ReactChildren,
} from '../../../types';

interface NotificationContext {
  notification: Notification;
  hide: () => void;
  notify: (notification: Partial<Notification>) => void;
}

const genericNotification: Notification = {
  message: 'placeholder text',
  type: 'message',
  visible: false,
  timeoutValue: NOTIFICATION_TIMEOUT,
};

const NotificationContext = createContext<NotificationContext | null>(null);

export function NotificationProvider({ children }: ReactChildren) {
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const [notification, setNotification] = useState<Notification>(genericNotification);

  const notify = useCallback((newNotification: Partial<Notification>) => {
    setNotification({ ...genericNotification, ...newNotification, visible: true });
  }, []);

  const hide = useCallback(() => {
    setNotification((prev) => ({ ...prev, visible: false }));
  }, []);

  useEffect(() => {
    if (notification.visible && notification.timeoutValue) {
      timeout.current = setTimeout(hide, notification.timeoutValue);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [hide, notification]);

  const providerValue = useMemo(() => ({ hide, notify, notification }), [hide, notification, notify]);

  return (
    <NotificationContext.Provider value={providerValue}>
      {children}
    </NotificationContext.Provider>
  );
}

export const useNotification = (): NotificationContext => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('useNotification must be wrapped within a <NofificationProvider>');
  }

  return context;
};
