import { useCallback, useState } from 'react';

import { useTournamentQuery } from './queries';
import { Button } from '../../../components/Button';
import { Tabs } from '../../../components/Tabs';
import { MatchesInfo } from './MatchesInfo';
import { TeamsInfo } from './TeamsInfo';
import { GroupsInfo } from './GroupsInfo';
import { TeamsRanking } from './TeamsRanking';
import { PlayersRanking } from './PlayersRanking';
import { CategoriesSelect } from './CategoriesSelect';
import { API } from '../../../../types';
import styles from './styles.module.scss';
import { useKeycloak } from '../../../services/keycloak';

enum MatchTab {
  MATCHES = 'Matches',
  TEAMS = 'Teams',
  GROUPS = 'Groups',
  PLAYERS = 'Players',
  TEAMS_RANKING = 'Teams Ranking',
}

const tournamentTabs = [
  { label: 'Matches', value: MatchTab.MATCHES },
  { label: 'Teams', value: MatchTab.TEAMS },
  { label: 'Groups', value: MatchTab.GROUPS },
  { label: 'Players Ranking', value: MatchTab.PLAYERS },
  { label: 'Teams Ranking', value: MatchTab.TEAMS_RANKING },
];

const renderCategoriesSelect = (
  tabName: MatchTab,
  selectedCategory: API.Category | null,
  handleSetCategory: (category: API.Category) => void,
) => {
  if (tabName === MatchTab.TEAMS || tabName === MatchTab.MATCHES) {
    return null;
  }
  return (
    <CategoriesSelect
      activeCategory={selectedCategory}
      setCategory={handleSetCategory}
    />
  );
};

export function TournamentInfo(): JSX.Element {
  const { keycloak } = useKeycloak();

  const [currentTab, setCurrentTab] = useState<MatchTab>(MatchTab.MATCHES);
  const [selectedCategory, setSelectedCategory] = useState<API.Category | null>(null);

  // Eventually tournament id will come from login session context, for now it should be 1
  const tournament = useTournamentQuery(1);

  const handleSetCategory = useCallback((category: API.Category) => setSelectedCategory(category), []);

  return (
    <section className={styles.sectionMargins}>
      {keycloak.authenticated && (
        <Button.GoBack className={styles.backButton}>BACK</Button.GoBack>
      )}
      <h1 className={styles.infoTitle}>{tournament.data?.name}</h1>
      <Tabs
        theme="main"
        activeTab={currentTab}
        tabsList={tournamentTabs}
        onTabSelect={(newTab) => setCurrentTab(newTab.value)}
      />
      {renderCategoriesSelect(currentTab, selectedCategory, handleSetCategory)}
      {
        {
          [MatchTab.MATCHES]: <MatchesInfo />,
          [MatchTab.TEAMS]: <TeamsInfo />,
          [MatchTab.GROUPS]: <GroupsInfo selectedCategoryLabel={selectedCategory?.categoryName} />,
          [MatchTab.PLAYERS]: <PlayersRanking selectedCategoryName={selectedCategory?.categoryName} />,
          [MatchTab.TEAMS_RANKING]: <TeamsRanking selectedCategoryId={selectedCategory?.id} />,
        }[currentTab]
      }
    </section>
  );
}
