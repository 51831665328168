import { SyntheticEvent } from 'react';

import { TeamDTO } from '../../../../../types/apiTypes';
import styles from './styles.module.scss';

interface TeamsProps {
  teams: TeamDTO[] | undefined
  selectedCategoryName: string | null;
  handleTeamSelect: (teamName: string) => (event: SyntheticEvent) => void
}
export function TeamsDropdown({ teams, selectedCategoryName, handleTeamSelect }: TeamsProps) {
  return (
    <ul className={styles.teamMenu}>
      {teams?.map((team) => (
        team.category === selectedCategoryName
          ? (
            <li key={team.id} className={styles.teamMenuElement}>
              <div
                className={styles.selectedCat}
                role="listbox"
                tabIndex={0}
                onClick={handleTeamSelect(team.teamName)}
              >
                <p className={styles.selectedValueTeam}>&emsp;&ensp;{team.teamName}</p>
              </div>
            </li>
          )
          : null
      ))}
    </ul>
  );
}
