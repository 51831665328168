import { Navigate } from 'react-router-dom';

import { useKeycloak } from '../../../services/keycloak';
import { getUserDashboardPath } from '../../../utils/helpers/utils';

export function RootRedirect(): JSX.Element {
  const { keycloak } = useKeycloak();
  const rootPath = getUserDashboardPath(keycloak);
  return <Navigate replace to={rootPath} />;
}
