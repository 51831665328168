import {
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import {
  Http,
  queryParams,
} from '../../../../services/http';
import {
  endpoints,
  REFETCH_INTERVAL,
} from '../../../../utils/constants';
import { API } from '../../../../../types';

const topScorersKeys = {
  all: ['topScorers'] as const,
  forCategory: (categoryName: string | undefined) => ['topScorers', categoryName] as const,
};

function fetchTopScorers(categoryName: string | undefined) {
  const scorersQuery = queryParams({ categoryName });
  return new Http<API.TopScore[]>(endpoints.goals.url + scorersQuery).get();
}

function transformTopScorers(topScorers: API.TopScore[]) {
  return topScorers
    .filter((score) => score.numberOfGoals)
    .sort(({ numberOfGoals: a }, { numberOfGoals: b }) => b - a);
}

export function useTopScorersQuery(categoryName: string | undefined): UseQueryResult<API.TopScore[], unknown> {
  return useQuery(topScorersKeys.forCategory(categoryName), () => fetchTopScorers(categoryName), {
    select: transformTopScorers,
    refetchInterval: REFETCH_INTERVAL,
    enabled: Boolean(categoryName),
  });
}
