import { useTopScorersQuery } from './PlayersRanking.queries';
import { ContentLoader } from '../../../../components/ContentLoader';
import styles from './styles.module.scss';

interface PlayersRankingProps {
  selectedCategoryName: string | undefined;
}

export function PlayersRanking({ selectedCategoryName }: PlayersRankingProps): JSX.Element {
  const topScorers = useTopScorersQuery(selectedCategoryName);

  return (
    <ContentLoader
      isFetching={topScorers.isLoading}
      fetchingMessage="Ranking data loading..."
      emptyMessage="No ranking data"
      content={topScorers.data}
    >
      <div className={styles.tableOverflow}>
        <table className={styles.table} cellSpacing={0}>
          <thead>
            <tr>
              <th>Place</th>
              <th>Full name</th>
              <th>Team</th>
              <th>Total goals</th>
              <th>Goals per match</th>
            </tr>
          </thead>
          <tbody>
            {topScorers.data?.map((score) => (
              <tr key={score.playerId}>
                <td>{score.place}</td>
                <td>{`${score.name} ${score.surname}`}</td>
                <td>{score.teamName}</td>
                <td>{score.numberOfGoals}</td>
                <td>{score.goalsPerMatch}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </ContentLoader>
  );
}
