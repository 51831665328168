import { matchesTableColumnCount } from './MatchesInfo.utils';

type IProps = {
  time: string;
  className: string;
};

export function InfoTableEmptyRow({ time, className }: IProps): JSX.Element {
  return (
    <tr className={className}>
      {Array
        .from({ length: matchesTableColumnCount })
        .map((_, i) => (i === 1 ? <td key={i}>{time}</td> : <td key={i} />))}
    </tr>
  );
}
