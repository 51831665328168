import { GenericButton } from './GenericButton';
import { GoBackButton } from './GoBack';
import { ToDashboardButton } from './ToDashboard';

type ButtonType = typeof GenericButton & {
  GoBack: typeof GoBackButton;
  ToDashboard: typeof ToDashboardButton;
}

export const Button = GenericButton as ButtonType;
Button.GoBack = GoBackButton;
Button.ToDashboard = ToDashboardButton;
