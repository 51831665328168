import {
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import { Http } from '../../services/http';
import { endpoints } from '../constants';
import { API } from '../../../types';

const categoriesKeys = {
  all: ['categories'] as const,
};

function fetchCategories() {
  return new Http<API.Category[]>(endpoints.categories.url).get();
}

function transformCategories(categories: API.Category[]): API.Category[] {
  return categories.sort((a, b) => a.rank - b.rank);
}

export function useCategoriesQuery(): UseQueryResult<API.Category[], unknown> {
  return useQuery(categoriesKeys.all, fetchCategories, {
    select: transformCategories,
  });
}
