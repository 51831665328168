import { createRoot } from 'react-dom/client';

import { getRootElement } from './app/utils/helpers/utils';
import { AppProviders } from './app/AppProviders';
import reportWebVitals from './app/reportWebVitals';
import './app/styles/reset.css';

const rootElement = getRootElement();
const root = createRoot(rootElement);

root.render(<AppProviders />);

reportWebVitals();
