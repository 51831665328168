interface SVGDotProps {
  className?: string;
  size?: number;
}

export function SVGDot({ size = 10, className }: SVGDotProps): JSX.Element {
  const totalSize = size * 2;

  return (
    <svg width={totalSize} height={totalSize} className={className}>
      <circle cx={size} cy={size} r={size} />
    </svg>
  );
}
