import { useNavigate } from 'react-router-dom';

import { useKeycloak } from '../../services/keycloak';
import canoeLogo from '../../statics/logos/canoeLogo.png';
import { getUserDashboardPath } from '../../utils/helpers/utils';
import { LoginActions } from './Login/LoginActions';
import { LogoutActions } from './Logout/LogoutActions';
import styles from './styles.module.scss';

export function Nav(): JSX.Element {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  return (
    <nav className={styles.navBar}>
      <div
        aria-hidden
        role="button"
        tabIndex={0}
        onClick={() => navigate(getUserDashboardPath(keycloak))}
        className={styles.logo}
      >
        <img src={canoeLogo} alt="canoe logo" />
      </div>
      {keycloak.authenticated ? <LogoutActions /> : <LoginActions />}
    </nav>
  );
}
