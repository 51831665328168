import { SyntheticEvent, useState } from 'react';

import { TeamDTO } from '../../../../../types/apiTypes';
import { ContentLoader } from '../../../../components/ContentLoader';
import { Icon } from '../../../../components/Icon';
import { useCategoriesQuery } from '../../../../utils/queries/useCategoriesQuery';
import { useTeamsQuery } from '../../../../utils/queries/useTeamsQuery';
import { CategoriesDropdown } from './CategoriesDropdown';
import { TeamDetails } from './TeamDetails';
import styles from './styles.module.scss';

const renderDropdownHeader = (categoryName: string | null, teamName: string | null) => {
  if (categoryName === null) {
    return 'No team selected';
  }
  if (teamName === null) {
    return categoryName;
  }
  return `${categoryName} > ${teamName}`;
};

export const getSelectedTeam = (teams: TeamDTO[], selectedTeamName: string) => {
  const selectedTeam = teams.find((team) => {
    return team.teamName === selectedTeamName;
  });

  return selectedTeam;
};

export function TeamsInfo(): JSX.Element {
  const { data: categories, isFetching: isCategoriesFetching } = useCategoriesQuery();
  const { data: teams, isFetching: isTeamsFetching } = useTeamsQuery();

  const [isOpenCategories, setisOpenCategories] = useState(false);
  const toggleisOpenCategories = () => {
    setisOpenCategories((prev) => !prev);
  };

  const [selectedTeamName, setSelectedTeamName] = useState<string | null>(null);
  const handleTeamSelect = (teamName: string) => (event: SyntheticEvent) => {
    event.stopPropagation();
    if (teamName === selectedTeamName) {
      setSelectedTeamName(null);
      return;
    }
    setSelectedTeamName(teamName);
    toggleisOpenCategories();
  };

  const [selectedCategoryName, setSelectedCategoryName] = useState<string | null>(null);
  const handleCategorySelect = (categoryName: string) => (event: SyntheticEvent) => {
    event.stopPropagation();
    setSelectedTeamName(null);
    if (categoryName === selectedCategoryName) {
      setSelectedCategoryName(null);
      return;
    }
    setSelectedCategoryName(categoryName);
  };

  const teamCanBeSelected = teams && selectedTeamName;
  const selectedTeam = teamCanBeSelected
    ? getSelectedTeam(teams, selectedTeamName)
    : null;
  return (
    <ContentLoader
      isFetching={isCategoriesFetching && isTeamsFetching}
      fetchingMessage="Loading teams data..."
      content={categories}
      emptyMessage="No teams data to display"
    >
      <div className={styles.dropdown}>
        <div className={styles.dropdownElements}>
          <div className={styles.selected} role="listbox" tabIndex={0} onClick={toggleisOpenCategories}>
            <p className={styles.selectedValue}>{renderDropdownHeader(selectedCategoryName, selectedTeamName)}</p>
            <Icon iconName="selectArrow" className={styles.dropdownIcon} />
            {isOpenCategories
              && (
                <CategoriesDropdown
                  categories={categories}
                  teams={teams}
                  selectedCategoryName={selectedCategoryName}
                  handleCategorySelect={handleCategorySelect}
                  handleTeamSelect={handleTeamSelect}
                />
              )}
          </div>
        </div>
        {selectedTeam
          && (
            <TeamDetails
              team={selectedTeam}
            />
          )}
      </div>
    </ContentLoader>
  );
}
