import clsx from 'clsx';
import { SVGProps } from 'react';

import { ReactComponent as Arrow } from '../../statics/icons/arrow.svg';
import { ReactComponent as Chevron } from '../../statics/icons/chevron.svg';
import { ReactComponent as Door } from '../../statics/icons/door.svg';
import { ReactComponent as SelectArrow } from '../../statics/icons/selectArrow.svg';
import { ReactComponent as CloseMark } from '../../statics/icons/closeMark.svg';
import { SVGDot } from './SVGDot';
import styles from './styles.module.scss';

type IconDirection = 'left' | 'top' | 'bottom' | 'right';

const iconsMap = {
  arrow: Arrow,
  chevron: Chevron,
  dot: SVGDot,
  door: Door,
  selectArrow: SelectArrow,
  closeMark: CloseMark,
} as const;

interface IconProps extends SVGProps<SVGSVGElement> {
  iconName: keyof typeof iconsMap;
  direction?: IconDirection;
  size?: number;
}

const directrionStyleMap: Record<IconDirection, string> = {
  top: styles.rotateTop,
  right: styles.rotateRight,
  bottom: styles.rotateBottom,
  left: styles.rotateLeft,
} as const;

export function Icon({
  iconName, direction = 'left', className, ...props
}: IconProps): JSX.Element {
  const IconComponent = iconsMap[iconName];
  return <IconComponent className={clsx(directrionStyleMap[direction], className)} {...props} />;
}
