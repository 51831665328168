import {
  ID,
  Primitive,
} from '../../../types';

export class Endpoint {
  public readonly url: string;

  constructor(path: string) {
    this.url = path;
  }

  public build(...args: Array<ID | null | undefined>): string {
    if ((this.url.match(/{id}/g) || []).length !== args.length) {
      throw new Error('Wrong amount of arguments passed to build method');
    }

    let builtUrl = this.url;

    args.forEach((arg) => {
      if (typeof arg === 'undefined' || arg === null) {
        throw new TypeError('url arg should not be undefined or null');
      }
      builtUrl = builtUrl.replace(/{id}/, arg.toString());
    });

    return builtUrl;
  }
}

export function queryParams(parameters: Record<string, Primitive | Array<Primitive>>): string {
  let query = '?';

  Object.entries(parameters).forEach(([paramName, paramValue]) => {
    if (Array.isArray(paramValue)) {
      paramValue.forEach((value) => {
        query += `${paramName}=${value}&`;
      });
    } else {
      query += `${paramName}=${paramValue}&`;
    }
  });

  return query.slice(0, -1);
}
