export { AppRoutes } from './routes';
export * as endpoints from './endpoints';

export const REFETCH_INTERVAL = 30_000;
export const NOTIFICATION_TIMEOUT = 2_000;
export const CAPTAIN_ROLE_ABBR = 'C';
export const REFEREE_ROLE_ABBR = 'R';
export const REFEREE_AND_CAPTAIN_ROLE_ABBR = `${CAPTAIN_ROLE_ABBR} ${REFEREE_ROLE_ABBR}`;

export enum UserRole {
  ADMIN = 'admin',
  PITCH = 'pitch',
  TEAM_LEADER = 'team_leader',
}

export enum Radix {
  DEC = 10,
}

/**
 * FEATURE TOGGLE
 *
 * when true then shows modals that allow to manually set player statuses
 *
 * when false all player statuses are active and no sprint player is set
 */
export const ALLOW_SENDING_STATUSES = false;

// match stages are counted from 0
export const TOURNAMENT_STAGE_LIMIT = 9;

export const MIN_MATCH_HALF = 1;
export const MAX_MATCH_HALF = 9;

export const MIN_GROUP_CAPACITY = 1;
export const MAX_GROUP_CAPACITY = 99;

export const INITIAL_ACTIVE_PLAYERS = 8;
export const MIN_ACTIVE_PLAYERS_NOT_INITIAL = 3;
export const MIN_ACTIVE_PLAYERS_INITIAL = 5;
export const MAX_ACTIVE_PLAYERS = 8;
