import { removeUnderscore } from '../../../../utils/helpers/utils';
import {
  API,
  GroupStatus,
} from '../../../../../types';

type TableHeader = (string | { label: string, span: number })[];
type MatchResults = ([number, number] | [null, null] | []);
type TableBody = (MatchResults | string | number)[][];

interface TableCell {
  groupLabel: string;
  cellWidth: string;
  tableHead: TableHeader;
  tableBody: TableBody;
  groupName: string;
}

function createTableHead(): TableHeader {
  return ['No.', 'TEAM', 'POINTS', 'W', 'D', 'L', 'G+', 'G-', '+/-'];
}

function createTableBody(
  teamDetails: API.TeamDetailsDTO[],
): TableBody {
  const sortedTeams = teamDetails.sort((a, b) => a.place - b.place);
  return sortedTeams.map(({
    name, points, wins, draws, loses, scored, lost, goalDiff, place,
  }) => {
    return [place, `${name}`, points, wins, draws, loses, scored, lost, goalDiff];
  });
}

function composeResultsMatrix(groupMatch: API.GroupDataDTO, groupStatus: GroupStatus): TableCell {
  const teamsCount = groupMatch.teamDetails.length;
  const cellWidth = (1 / teamsCount) * 100;
  const tableHead = createTableHead();
  const tableBody = createTableBody(groupMatch.teamDetails);
  const groupLabel = `Group ${groupMatch.groupName} (${removeUnderscore(groupStatus)})`;
  const { groupName } = groupMatch;

  return {
    tableHead,
    tableBody,
    cellWidth: cellWidth.toString(),
    groupLabel,
    groupName,
  };
}

export function createStagesMap(
  allGroups:API.GroupDTO[] | undefined,
  groupsDetails: API.GroupDataDTO[],
): TableCell[][] {
  const stagesMap: Record<number, TableCell[]> = {};

  if (!groupsDetails || !allGroups) {
    return [];
  }

  groupsDetails.forEach((group) => {
    if (!(group.stage in stagesMap)) {
      stagesMap[group.stage] = [];
    }
    const groupStatus = allGroups.find(({ id }) => id === group.groupId)?.status;
    if (!groupStatus) {
      throw new Error('Group status not found');
    }
    const groupTable = composeResultsMatrix(group, groupStatus);
    stagesMap[group.stage].push(groupTable);
  });
  return Object.values(stagesMap);
}
