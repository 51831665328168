import {
  AuthState,
  useKeycloak,
} from './services/keycloak';
import { useOnlineStatus } from './utils/hooks/useOnlineStatus';
import { isAuthTokenValid } from './utils/helpers/utils';
import { OfflineMode } from './components/OfflineMode';
import { NoAuthServiceMode } from './components/Authorization/NoAuthServiceMode';
import { AppRouter } from './components/Routing/AppRouter';
import { AppLayout } from './components/Layout/AppLayout';
import { ErrorPage } from './views/public/ErrorPage';
import { LoadingPage } from './views/public/LoadingPage';

export function App(): JSX.Element {
  const isOnline = useOnlineStatus();
  const { authState, keycloak } = useKeycloak();

  if (!isOnline) {
    return <OfflineMode />;
  }

  if (authState === AuthState.KEYCLOAK_INIT_ERROR) {
    return <NoAuthServiceMode />;
  }

  if (authState === AuthState.LOADING) {
    return <LoadingPage loadingText="Checking permissions..." />;
  }

  if (authState === AuthState.AUTHENTICATED && !isAuthTokenValid(keycloak.tokenParsed)) {
    return <ErrorPage title="Critical error: Wrong auth token format" />;
  }

  return <AppRouter layout={<AppLayout />} />;
}
