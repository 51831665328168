import clsx from 'clsx';

import { allowedMatchStatusesMap } from './MatchesInfo.utils';
import { Icon } from '../../../../components/Icon';
import { API, MatchStatus } from '../../../../../types';
import styles from './styles.module.scss';

interface InfoTableRowProps {
  time: string;
  matches: API.Match[];
  className: string;
}

const statusColorMap: Partial<Record<MatchStatus, string>> = {
  [MatchStatus.FINISHED]: 'finished',
  [MatchStatus.IN_PROGRESS]: 'inProgress',
};

const composeReferee = ({
  primaryRefereeTeam, secondaryRefereeTeam, refereePrimary, refereeSecondary,
}: API.Match) => {
  if (!primaryRefereeTeam && !refereePrimary.name) return '';

  const primaryReferee = primaryRefereeTeam?.teamName || `${refereePrimary.surname} ${refereePrimary.name}`;
  const secondaryReferee = secondaryRefereeTeam?.teamName || `${refereeSecondary.surname} ${refereeSecondary.name}`;

  if (primaryReferee === secondaryReferee) return primaryReferee;
  return `${primaryReferee} / ${secondaryReferee}`;
};

export function InfoTableRow({
  time, matches, className,
}: InfoTableRowProps): JSX.Element {
  return (
    <>
      {matches.map((match, i) => (
        <tr key={match.id} className={className}>
          <td>{match.matchNumber}</td>
          <td rowSpan={matches.length} className={clsx({ [styles.hide]: i !== 0 })}>{time}</td>
          <td>{match.pitchNumber}</td>
          <td>{match.categoryName}</td>
          <td>{match.groupName}</td>
          <td>{match.team1Name}</td>
          <td>{match.team2Name}</td>
          <td>{match.team1Goals}</td>
          <td>{match.team2Goals}</td>
          <td>{composeReferee(match)}</td>
          <td>{match.tableRefereeTeam?.teamName}</td>
          <td>
            {allowedMatchStatusesMap[match.status]
          && (
          <div className={clsx(styles.playerStatus, styles[statusColorMap[match.status] ?? ''])}>
            <Icon
              iconName="dot"
              size={3}
              className={clsx({ [styles.animateBlink]: match.status === MatchStatus.IN_PROGRESS })}
            />
            <span>{allowedMatchStatusesMap[match.status]}</span>
          </div>
          )}
          </td>
        </tr>
      ))}
    </>
  );
}
