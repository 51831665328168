import { StrictMode } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import { NotificationProvider } from './utils/context/NotificationContext';
import { queryClient } from './services/reactQuery';
import { browserHistory } from './services/history';
import { KeycloakProvider } from './services/keycloak/keycloakProvider';
import { keycloakClient } from './services/keycloak';
import { App } from './App';

export function AppProviders(): JSX.Element {
  return (
    <StrictMode>
      <KeycloakProvider client={keycloakClient}>
        <QueryClientProvider client={queryClient}>
          <NotificationProvider>
            <HistoryRouter history={browserHistory}>
              <App />
            </HistoryRouter>
          </NotificationProvider>
        </QueryClientProvider>
      </KeycloakProvider>
    </StrictMode>
  );
}
