import { Outlet } from 'react-router-dom';

import { OfflineBanner } from '../OfflineMode/OfflineBanner';

export function OfflineLayout() {
  return (
    <>
      <OfflineBanner />
      <main>
        <Outlet />
      </main>
    </>
  );
}
