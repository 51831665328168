import { keycloakClient } from '../keycloak';
import { APIError } from './APIError';

export class Http<T> {
  private readonly url: string;

  private readonly init: RequestInit;

  private responseType: 'json' | 'blob' = 'json';

  constructor(url: string, init: RequestInit = {}) {
    this.url = url;
    this.init = { ...init };

    if (!this.init.headers) {
      this.init.headers = new Headers();
    }

    if (keycloakClient.token) {
      (this.init.headers as Headers).append('Authorization', `Bearer ${keycloakClient.token}`);
    }

    if (!(this.init.headers as Headers).has('Accept')) {
      (this.init.headers as Headers).append('Accept', 'application/json');
      (this.init.headers as Headers).append('Content-Type', 'application/json');
    }
  }

  private async fetch(): Promise<T> {
    const response = await fetch(this.url, this.init);

    switch (this.responseType) {
      case 'json': {
        const text = await response.text();
        const json = text ? JSON.parse(text) : {};

        if (!response.ok) {
          throw new APIError(response.status, json);
        }

        return json as T;
      }
      case 'blob': {
        if (!response.ok) {
          const text = await response.text();
          const json = text ? JSON.parse(text) : {};

          throw new APIError(response.status, json);
        }

        return response.blob() as unknown as T;
      }
      default: throw new Error('unhandled response type');
    }
  }

  readAsBlob(): Http<T> {
    this.responseType = 'blob';
    return this;
  }

  delete(): Promise<T> {
    this.init.method = 'DELETE';
    return this.fetch();
  }

  get(): Promise<T> {
    this.init.method = 'GET';
    return this.fetch();
  }

  post(body?: Record<string, unknown>): Promise<T> {
    this.init.method = 'POST';
    this.init.body = JSON.stringify(body);
    return this.fetch();
  }

  put(body?: Record<string, unknown>): Promise<T> {
    this.init.method = 'PUT';
    this.init.body = JSON.stringify(body);
    return this.fetch();
  }

  patch(body?: Record<string, unknown>): Promise<T> {
    this.init.method = 'PATCH';
    this.init.body = JSON.stringify(body);
    return this.fetch();
  }
}
