import { Link } from 'react-router-dom';

import { useKeycloak } from '../../services/keycloak';
import { getUserDashboardPath } from '../../utils/helpers/utils';
import styles from './styles.module.scss';

export function OfflineBanner(): JSX.Element {
  const { keycloak } = useKeycloak();

  return (
    <div className={styles.offlineBanner}>
      {keycloak.authenticated
        && <Link className={styles.dashboardLink} to={getUserDashboardPath(keycloak)}>View dashboard</Link>}
      <h1>You are currently offline. Check your internet connection.</h1>
    </div>
  );
}
