import { useKeycloak } from '../../../services/keycloak';
import { Button } from '../../Button';
import { EmphasizedText } from '../../EmphasizedText';
import { Icon } from '../../Icon';
import styles from './styles.module.scss';

const logoutPath = process.env.REACT_APP_LOGOUT_URL;

export function LogoutActions(): JSX.Element {
  const { keycloak } = useKeycloak();

  return (
    <div className={styles.logoutActions}>
      <p className={styles.userDetails}>Logged as:&nbsp;
        <EmphasizedText>
          {keycloak.tokenParsed?.given_name}
          {' '}
          {keycloak.tokenParsed?.family_name}
        </EmphasizedText>
      </p>
      <Button
        icon={{ position: 'beginning', src: <Icon iconName="door" /> }}
        onClick={() => keycloak.logout({ redirectUri: logoutPath })}
      >
        LOGOUT
      </Button>
    </div>
  );
}
