import { TeamLeader } from './teamLeader';
import { Player } from './player';
import { Referee } from './referee';
import { PlayerAsReferee } from './playerAsReferee';
import {
  NoNullFields,
  Overwrite,
} from './utils';
import { TeamAsReferee } from './teamAsReferee';
import { ID } from './id';
import { MatchPlayer } from './match';

export type Category = {
  id: ID;
  capacity: number;
  categoryName: string;
  numberOfRefereesPerTeam: number;
  numberOfTeams: number;
  rank: number;
  ranking: number[];
};

export type NewCategory = Pick<Category, 'capacity' | 'categoryName' | 'rank'>;

export enum GroupStatus {
  CREATED = 'CREATED',
  MATCHES_GENERATED = 'MATCHES_GENERATED',
  CLOSED = 'CLOSED',
}

export enum GameSystemEnum {
  DOUBLE_MATCH = 'DOUBLE_MATCH',
  FREE_PASS = 'FREE PASS',
  ROUND_ROBIN = 'ROUND_ROBIN',
  FINAL_MATCH = 'FINAL MATCH',
  SIX_TEAM_CROSS = 'SIX_TEAM_CROSS',
}

export type GroupDTO = {
  id: ID;
  categoryName: string;
  name: string;
  capacity: number;
  numberOfTeams: number;
  tournamentStage: number;
  status: GroupStatus;
  gameSystem: GameSystemEnum;
  isWinToGo: boolean;
  inRanking: boolean;
};

export enum TeamStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  IN_REVIEW = 'IN_REVIEW',
  ON_HOLD = 'ON_HOLD',
}

export type TeamDTO = {
  id: ID;
  teamAcronym: string;
  teamName: string;
  category: string | null;
  teamLeader: TeamLeader;
  players: Player[];
  referees: Referee[];
  registrationStatus: TeamStatus;
  rejectReason: string | null;
  submitterUsername: string;
  isPlaceholder: boolean;
}

export type NewTeam = Pick<TeamDTO, 'teamName' | 'teamAcronym' | 'category' | 'teamLeader'> & {
  players: Overwrite<Player, { id: ID | null }>[];
  referees: Overwrite<Referee, { id: ID | null }>[];
}

export type MatchInGroup = {
  id: ID;
  status: 'CREATED' | 'IN_PROGRESS' | 'ASSIGNED' | 'FINISHED';
  team1Id: string;
  team2Id: string;
};

export enum MatchStatus {
  ASSIGNED = 'ASSIGNED',
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  EDITED = 'EDITED',
  EDITING = 'EDITING',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
}

export type Match = {
  id: ID;
  matchNumber: string | null;
  categoryName: string;
  groupName: string;
  team1Id: ID | null;
  team1Name: string;
  team1Goals: number | null;
  team1Players: MatchPlayer[];
  team1Approval: boolean;
  primaryRefereeTeam: TeamAsReferee | null;
  team2Id: ID | null;
  team2Name: string;
  team2Goals: number | null;
  team2Approval: boolean;
  team2Players: MatchPlayer[];
  secondaryRefereeTeam: TeamAsReferee | null;
  timeSlotHours: string | null;
  timeSlotMinutes: string | null;
  status: MatchStatus;
  day: string | null;
  pitchNumber: string | null;
  categoryRank: number;
  tournamentStage: number;
  refereePrimary: PlayerAsReferee;
  refereeSecondary: PlayerAsReferee;
  tableRefereeTeam: TeamAsReferee | null;
  gamePeriod: number | null;
  creationDate: string;
  version: number;
};

export type CreatedMatch = Match & {
  status: MatchStatus.CREATED;
};

export type AssignedMatch = NoNullFields<Match> & {
  primaryRefereeTeam: TeamAsReferee | null;
  secondaryRefereeTeam: TeamAsReferee | null;
  status: MatchStatus.ASSIGNED;
};

export type InProgressMatch = NoNullFields<Match> & {
  primaryRefereeTeam: TeamAsReferee | null;
  secondaryRefereeTeam: TeamAsReferee | null;
  status: MatchStatus.IN_PROGRESS;
};

export type FinishedMatch = NoNullFields<Match> & {
  primaryRefereeTeam: TeamAsReferee | null;
  secondaryRefereeTeam: TeamAsReferee | null;
  status: MatchStatus.FINISHED;
};

export type PendingApprovalMatch = NoNullFields<Match> & {
  primaryRefereeTeam: TeamAsReferee | null;
  secondaryRefereeTeam: TeamAsReferee | null;
  status: MatchStatus.PENDING_APPROVAL;
};

export type TeamDetailsDTO = {
  id: number;
  name: string;
  acronym: string;
  points: number;
  scored: number;
  lost: number;
  wins: number;
  draws: number;
  loses: number;
  goalDiff: number;
  greenCards: number;
  yellowCards: number;
  redCards: number;
  place: number;
};

export type MatchDetailsDTO = {
  team1Id: number;
  team2Id: number;
  team1Score: number;
  team2Score: number;
}

export type GroupDataDTO = {
  groupId: ID,
  groupName: string;
  categoryName: string;
  stage: number;
  teamDetails: TeamDetailsDTO[];
  matchesDetails: MatchDetailsDTO[];
}

export type Ranking = {
  id: ID;
  teamName: string;
  placeInCategory: number;
}

export type RefereeTeamSelect = {
  teamId: ID;
  category: string;
  teamName: string;
  numberOfMatchesAssigned: number;
  placeholder: boolean;
};

export type RefereeSelect = {
  teamMemberId: ID;
  name: string;
  surname: string;
  numberOfMatchesAssigned: number;
};

export type Goal = {
  id: ID;
  gamePart: number;
  minutes: number;
  seconds: number;
  scorerTeamId: ID;
  playerNumber: number;
  playerName: string;
  playerSurname: string;
  ownGoal: boolean;
  team1Score: number;
  team2Score: number;
};

export type Card = {
  id: ID;
  gamePart: number;
  color: string;
  powerPlay: boolean;
  minutes: number;
  seconds: number;
  teamId: number;
  playerNumber: number;
  playerName: string;
  playerSurname: string;
};

export type NewGoal = {
  gamePart: number;
  minutes: number;
  seconds: number;
  scorerTeamId: ID;
  playerNumber: number;
  ownGoal: boolean;
};

export type NewCardData = {
  gamePart: number;
  color: string;
  powerPlay: boolean;
  minutes: number;
  seconds: number;
  playerId: number;
};

export type TopScore = {
  playerId: ID,
  name: string;
  surname: string;
  numberOfGoals: number;
  teamName: string;
  goalsPerMatch: string;
  numberOfMatches: string;
  place: number;
};

export enum ReviewStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  IN_REVIEW = 'IN_REVIEW',
  ON_HOLD = 'ON_HOLD',
}

export type RegistrationRequestDTO = {
  id: ID;
  teamId: ID;
  teamName: string;
  preferredCategory: string;
  creationDate: Date;
  submitterUsername: null | string;
  note: null | string;
  status: ReviewStatus;
}

export interface TeamLeaderDTO {
  isPresent: boolean;
}

export interface MatchSummaryDTO {
  matchId: number;
  myTeamId: ID;
  myTeamName: string;
  myScore: number;
  opponentTeamId: ID;
  opponentTeamName: string;
  opponentScore: number;
  day: number;
  hours: string;
  minutes: string;
}

export interface PlayerStatusesDTO {
  id: ID;
  teamId: ID;
  gamePart: number;
  activePlayersId: Array<ID>;
  sprintPlayerId: ID | null;
}

export enum ConflictStatus {
  NEW = 'NEW'
}

export enum ConflictType {
  MATCH_RESULT_REJECTED = 'MATCH_RESULT_REJECTED',
  GROUP_POINTS_CONFLICT = 'GROUP_POINTS_CONFLICT'
}

export interface ConflictDTO {
  id: ID;
  status: ConflictStatus;
  type: ConflictType;
  matchId: ID;
  matchNumber: number;
  groupId: ID;
}

export interface TournamentDTO {
  id: ID;
  name: string
}
