import Keycloak from 'keycloak-js';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ReactChildren } from '../../../types';

export enum AuthState {
  LOADING = 'LOADING',
  KEYCLOAK_INIT_ERROR = 'KEYCLOAK_INIT_ERROR',
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}

interface KeycloakProviderI extends ReactChildren {
  client: Keycloak;
}

interface KeycloakContext {
  keycloak: Keycloak,
  authState: AuthState,
}

const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL;

export const keycloakClient = new Keycloak({
  url: keycloakUrl,
  realm: 'canoepolo',
  clientId: 'springboot-canoepolo',
});

const KeycloakContext = createContext<KeycloakContext | null>(null);

export function KeycloakProvider({ children, client }: KeycloakProviderI): JSX.Element {
  /**
   * @description Combining strict mode and check-sso results in infinite loop (related to redirection).
   */
  const isInit = useRef(false);
  const [authState, setAuthState] = useState<AuthState>(AuthState.LOADING);

  useEffect(() => {
    if (isInit.current) {
      return;
    }

    isInit.current = true;
    client
      .init({ onLoad: 'check-sso' })
      .then((isAuthenticated) => setAuthState(
        isAuthenticated
          ? AuthState.AUTHENTICATED
          : AuthState.UNAUTHENTICATED,
      ))
      .catch(() => setAuthState(AuthState.KEYCLOAK_INIT_ERROR))
      .finally(() => { isInit.current = false; });
  }, [client]);

  const provided: KeycloakContext = useMemo(() => ({
    authState,
    keycloak: client,
  }), [authState, client]);

  return (
    <KeycloakContext.Provider value={provided}>
      {children}
    </KeycloakContext.Provider>
  );
}

export function useKeycloak(): KeycloakContext {
  const context = useContext(KeycloakContext);

  if (!context) {
    throw new Error('useKeycloak must be wrapped by <KeycloakProvider> component');
  }

  return context;
}
