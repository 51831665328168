import { GroupDataDTO, MatchDetailsDTO } from '../../../../../../types/apiTypes';
import styles from './styles.module.scss';

interface GroupMatchesInfoProps {
  groupDetails: GroupDataDTO[];
  groupName: string;
}

export default function GroupMatchesInfo({ groupDetails, groupName }: GroupMatchesInfoProps): JSX.Element {
  const groupIndex = groupDetails.findIndex((group) => group.groupName === groupName);

  if (groupIndex === -1) {
    return <div>Group {groupName} was not found</div>;
  }

  const { matchesDetails, teamDetails } = groupDetails[groupIndex];

  const resultMatrix: { [team1Id: number]: { [team2Id: number]: string } } = {};

  matchesDetails.forEach((match: MatchDetailsDTO) => {
    if (!resultMatrix[match.team1Id]) {
      resultMatrix[match.team1Id] = {};
    }
    if (!resultMatrix[match.team2Id]) {
      resultMatrix[match.team2Id] = {};
    }

    resultMatrix[match.team1Id][match.team2Id] = match.team1Score !== null && match.team2Score !== null
      ? `${match.team1Score}-${match.team2Score}`
      : ' - ';

    resultMatrix[match.team2Id][match.team1Id] = match.team1Score !== null && match.team2Score !== null
      ? `${match.team2Score}-${match.team1Score}`
      : ' - ';
  });

  return (
    <table cellSpacing={0} className={styles.table}>
      <thead>
        <tr>
          <th> </th>
          {teamDetails.map((team) => (
            <th key={team.id} className={styles.acronym}>{team.acronym}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {teamDetails.map((team1) => (
          <tr key={team1.id}>
            <td className={styles.acronymRows}>{`(${team1.acronym}) ${team1.name}`}</td>
            {teamDetails.map((team2) => (
              <td key={team2.id}>
                {resultMatrix[team1.id][team2.id] || '-'}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
