import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';

import { Icon } from '../Icon';
import { useDetectClickOutside } from '../../utils/hooks/useDetectClickOutside';
import { SelectOption } from '../../../types';
import styles from './styles.module.scss';

interface Select <T> {
  defaultValue?: SelectOption<T> | null;
  selectValue?: SelectOption<T> | null;
  options: SelectOption<T>[];
  className?: string;
  isFetching?: boolean;
  noOptionsLabel?: string;
  orientation?: 'downward' | 'upward';
}

interface ClearableSelect<T> extends Select<T> {
  placeholder: string;
  clearable: true;
  onChange(value: T | null): void;
}

interface NonClearableSelect<T> extends Select<T> {
  placeholder?: string;
  clearable?: false;
  onChange(value: T): void;
}

type SelectProps<T> = ClearableSelect<T> | NonClearableSelect<T>;

const fetchingLabel = 'Loading data...';

export function Select<T extends string | number>({
  defaultValue,
  options,
  onChange,
  className,
  clearable,
  selectValue,
  isFetching = false,
  placeholder = 'Select option',
  noOptionsLabel = 'No data to select from',
  orientation = 'downward',
}: SelectProps<T>): JSX.Element {
  const selectElement = useRef(null);

  const [selectedOption, setSelectedOption] = useState<null | SelectOption<T>>(defaultValue || null);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (typeof selectValue !== 'undefined') {
      setSelectedOption(selectValue);
    }
  }, [selectValue]);

  useDetectClickOutside(selectElement, useCallback(() => setIsExpanded(false), []));

  const getSelectValue = (): string => {
    if (isFetching) {
      return fetchingLabel;
    }
    if (!options.length && !selectedOption) {
      return noOptionsLabel;
    }
    return selectedOption?.label
      || selectedOption?.value.toString()
      || placeholder;
  };

  const toggleDropdown = (): void => {
    if (!options.length) return;
    setIsExpanded((prev) => !prev);
  };

  const handleOptionSelect = (option: SelectOption<T>): void => {
    if (option.disabled) return;
    setSelectedOption(option);
    onChange(option.value);
    setIsExpanded(false);
  };

  const handleUnselect = (): void => {
    setSelectedOption(null);
    if (clearable) {
      onChange(null);
    }
  };

  const dropDownStyle = clsx(styles.dropdown, {
    [styles.upward]: orientation === 'upward',
  });

  const isArrowIcon = Boolean(options.length) && !isFetching;
  const isClearable = Boolean(clearable && selectedOption?.value);

  return (
    <div className={clsx(styles.select, className)} ref={selectElement}>
      <div className={styles.selected} role="listbox" tabIndex={0} onClick={toggleDropdown}>
        <p className={styles.selectedValue}>{getSelectValue()}</p>
        {isClearable && <button type="button" className={styles.clearIcon} onClick={handleUnselect}>×</button>}
        {isArrowIcon && <Icon iconName="selectArrow" className={styles.dropdownIcon} />}
      </div>
      {isExpanded && (
      <div className={dropDownStyle}>
        {options.map((option) => (
          <button type="button" key={option.value} className={styles.option} onClick={() => handleOptionSelect(option)}>
            {option.label || option.value}
          </button>
        ))}
      </div>
      )}
    </div>
  );
}
