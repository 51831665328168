import {
  useEffect,
  useMemo,
} from 'react';

import { useCategoriesQuery } from '../../../../utils/queries/useCategoriesQuery';
import { Select } from '../../../../components/Select/Select';
import { Tabs } from '../../../../components/Tabs';
import styles from './styles.module.scss';
import {
  API,
  ID,
  Tab,
} from '../../../../../types';

interface CategoriesSelectProps {
  activeCategory: API.Category | null;
  setCategory(newCategory: API.Category | null): void;
}

export function CategoriesSelect({ activeCategory, setCategory }: CategoriesSelectProps): JSX.Element | null {
  const categories = useCategoriesQuery();

  useEffect(() => {
    const firstCategory = categories.data?.at(0);
    if (!activeCategory && firstCategory) {
      setCategory(firstCategory);
    }
  }, [activeCategory, categories.data, setCategory]);

  const categoriesSelect = useMemo(() => {
    if (!categories.data) return [];
    return categories.data.map(({ id, categoryName }) => ({ value: id, label: categoryName }));
  }, [categories.data]);

  const selectCategory = activeCategory
    ? { value: activeCategory.id, label: activeCategory.categoryName }
    : null;

  const handleSelectOptionChange = (selectedCategoryId: ID) => {
    const newCategory = categories.data?.find(({ id }) => id === selectedCategoryId);
    if (!newCategory) return;
    setCategory(newCategory);
  };

  const handleTabSelect = (selectedTab: Tab<string | number>) => {
    const newCategory = categories.data?.find(({ id }) => id === selectedTab.value);
    if (!newCategory) return;
    setCategory(newCategory);
  };

  if (!categories.data?.length) return null;

  return (categories.data.length) > 3
    ? (
      <Select
        className={styles.select}
        selectValue={selectCategory}
        options={categoriesSelect}
        onChange={handleSelectOptionChange}
      />
    )
    : (
      <Tabs
        theme="context"
        activeTab={activeCategory?.id ?? null}
        tabsList={categoriesSelect}
        onTabSelect={handleTabSelect}
      />
    );
}
