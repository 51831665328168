import { Outlet } from 'react-router-dom';

import { Nav } from '../Nav';
import { Notification } from '../Notification';

export function AppLayout(): JSX.Element {
  return (
    <>
      <Nav />
      <Notification />
      <main>
        <Outlet />
      </main>
    </>
  );
}
