import clsx from 'clsx';

import { EmphasizedText } from '../EmphasizedText';
import { Icon } from '../Icon';
import styles from './styles.module.scss';

interface DayControlProps {
  day: number;
  limit: number;
  incrementDay(): void;
  decrementDay(): void;
}

export function DayControl({
  day, limit, decrementDay, incrementDay,
}: DayControlProps): JSX.Element {
  return (
    <div className={styles.dayController}>
      <p>Day</p>
      <Icon
        iconName="chevron"
        direction="left"
        className={clsx(styles.icon, { [styles.hide]: day === 1 })}
        onClick={decrementDay}
      />
      <EmphasizedText>{day}</EmphasizedText>
      <Icon
        iconName="chevron"
        direction="right"
        className={clsx(styles.icon, { [styles.hide]: day === limit })}
        onClick={incrementDay}
      />
    </div>
  );
}
