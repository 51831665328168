import { SyntheticEvent } from 'react';

import { Icon } from '../../../../components/Icon';
import { TeamsDropdown } from './TeamsDropdown';
import { Category, TeamDTO } from '../../../../../types/apiTypes';
import styles from './styles.module.scss';

interface CategoriesProps {
  categories: Category[] | undefined;
  teams: TeamDTO[] | undefined;
  selectedCategoryName: string | null;
  handleCategorySelect: (categoryName: string) => (event: SyntheticEvent) => void;
  handleTeamSelect: (teamName: string) => (event: SyntheticEvent) => void
}

export function CategoriesDropdown(
  {
    categories, teams, selectedCategoryName, handleCategorySelect, handleTeamSelect,
  }: CategoriesProps,
): JSX.Element {
  return (
    <ul className={styles.menu}>
      {categories?.map((category) => (
        <li key={category.id} className={styles.menuElement}>
          <div
            className={styles.categoryInDropdown}
            role="listbox"
            tabIndex={0}
            onClick={handleCategorySelect(category.categoryName)}
          >
            <p className={styles.selectedValueCat}>{category.categoryName}</p>
            <Icon iconName="selectArrow" className={styles.dropdownIcon} />
          </div>
          {category.categoryName === selectedCategoryName
            ? (
              <TeamsDropdown
                teams={teams}
                selectedCategoryName={selectedCategoryName}
                handleTeamSelect={handleTeamSelect}
              />
            )
            : null }
        </li>
      ))}
    </ul>
  );
}
