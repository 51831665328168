import { useState } from 'react';

import { OfflineLayout } from '../Layout/OfflineLayout';
import { AppRouter } from '../Routing/AppRouter';
import { Button } from '../Button';
import styles from './styles.module.scss';

export function OfflineMode(): JSX.Element {
  const [isOfflineModeAccepted, setIsOfflineModeAccepted] = useState(false);

  if (!isOfflineModeAccepted) {
    return (
      <div className={styles.offlineModeInfo}>
        <p>You are currently in offline mode.</p>
        <p>Internet connection is required to make changes to the system.</p>
        <Button onClick={() => setIsOfflineModeAccepted(true)}>Accept</Button>
      </div>
    );
  }

  return <AppRouter layout={<OfflineLayout />} />;
}
