import {
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import { Http } from '../../../services/http';
import { endpoints } from '../../../utils/constants';
import {
  API,
  ID,
} from '../../../../types';

const tournamentKeys = {
  byTournamentId: (tournamentId: ID) => ['tournament', tournamentId] as const,
};

function fetchTournament(tournamentId: ID): Promise<API.TournamentDTO> {
  return new Http<API.TournamentDTO>(endpoints.tournament.build(tournamentId)).get();
}

export function useTournamentQuery(tournamentId: ID): UseQueryResult<API.TournamentDTO, unknown> {
  return useQuery(
    tournamentKeys.byTournamentId(tournamentId),
    () => fetchTournament(tournamentId),
  );
}
