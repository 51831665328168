import { useEffect, useState } from 'react';

import { createStagesMap } from './GroupsInfo.utils';
import {
  useGroupsQuery,
  useGroupsDetailsQuery,
} from './GroupsInfo.queries';
import { ContentLoader } from '../../../../components/ContentLoader';
import styles from './styles.module.scss';
import { Button } from '../../../../components/Button';
import { Icon } from '../../../../components/Icon';
import GroupMatchesInfo from './GroupMatchesInfo/GroupMatchesInfo';

interface GroupsInfoProps {
  selectedCategoryLabel: string | undefined;
}

export function GroupsInfo({ selectedCategoryLabel }: GroupsInfoProps): JSX.Element {
  const allGroups = useGroupsQuery(selectedCategoryLabel);
  const groupsDetails = useGroupsDetailsQuery(allGroups.data);
  const [showMatchesResults, setshowMatchesResults] = useState<{ [key: string]: boolean }>({});
  const [isShortView, setIsShortView] = useState(false);

  const checkShortView = () => {
    setIsShortView(window.innerWidth < 1100);
  };

  useEffect(() => {
    checkShortView();
    window.addEventListener('resize', checkShortView);
    return () => {
      window.removeEventListener('resize', checkShortView);
    };
  }, []);

  const toggleMatchesVisibility = (groupId: string) => {
    setshowMatchesResults((prevMap) => ({
      ...prevMap,
      [groupId]: !prevMap[groupId],
    }));
  };

  return (
    <ContentLoader
      isFetching={allGroups.isLoading}
      fetchingMessage="Loading groups data..."
      content={allGroups.data}
      emptyMessage="No groups data to display"
    >
      <>
        {createStagesMap(allGroups.data, groupsDetails).map((stagesList, stageIndex) => (
          <div key={stageIndex}>
            <hr className={styles.separatorLine} />
            <h1 className={styles.stageLabel}>Stage: {stageIndex + 1}</h1>
            {stagesList.map(({
              tableHead, tableBody, cellWidth, groupLabel, groupName,
            }) => (
              <div key={groupLabel} className={styles.groupTable}>
                <p className={styles.groupName}>{groupLabel}</p>
                <div className={styles.tableWrapper}>
                  <table cellSpacing={0} className={`${styles.table} ${styles.firstTable}`}>
                    <thead>
                      <tr>
                        {tableHead.map((head, j) => (
                          typeof head === 'string' ? (
                            <th key={j} className={styles.tableHead}>
                              {head}
                            </th>
                          ) : (
                            <th key={j} style={{ width: `${cellWidth}%` }} colSpan={head.span}>
                              {head.label}
                            </th>
                          )
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tableBody.map((row, k) => (
                        <tr key={k}>
                          {row.map((cell, j) => (
                            <td key={`${j}${k}`}>
                              {Array.isArray(cell) ? `${cell[0]} - ${cell[1]}` : cell}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {isShortView ? (
                    <>
                      <Button
                        icon={{
                          position: 'end',
                          src: showMatchesResults[groupName]
                            ? <Icon iconName="chevron" /> : <Icon iconName="chevron" direction="bottom" />,
                        }}
                        className={styles.showMatchesButton}
                        onClick={() => toggleMatchesVisibility(groupName)}
                      >
                        {showMatchesResults[groupName] ? 'hide group results' : 'show group results'}
                        <span className={styles.buttonGap} />
                      </Button>
                      {showMatchesResults[groupName]
                        && <GroupMatchesInfo groupDetails={groupsDetails} groupName={groupName} />}
                    </>
                  ) : (
                    <GroupMatchesInfo groupDetails={groupsDetails} groupName={groupName} />
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </>
    </ContentLoader>
  );
}
