import styles from './styles.module.scss';

interface LoadingPageProps {
  loadingText: string;
}

export function LoadingPage({ loadingText }: LoadingPageProps): JSX.Element {
  return (
    <div className={styles.loadingWrapper}>
      <div className={styles.spinner} />
      <span>{loadingText}</span>
    </div>
  );
}
