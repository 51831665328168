import { Endpoint } from '../../../services/http';

const URL_DOMAIN = process.env.REACT_APP_BASE_URL;

// CATEGORIES DOMAIN
export const categories = new Endpoint(`${URL_DOMAIN}/categories`);
export const category = new Endpoint(`${categories.url}/{id}`);
export const categoryPlace = new Endpoint(`${category.url}/place/{id}`);
export const ranking = new Endpoint(`${category.url}/ranking`);

// GROUPS DOMAIN
export const groups = new Endpoint(`${URL_DOMAIN}/groups`);
export const group = new Endpoint(`${groups.url}/{id}`);
export const groupMatches = new Endpoint(`${group.url}/matches`);
export const groupTeams = new Endpoint(`${group.url}/teams`);
export const groupTeam = new Endpoint(`${groupTeams.url}/{id}`);
export const groupsConflict = new Endpoint(`${group.url}/resolve-conflict`);

// MATCHES DOMAIN
export const matches = new Endpoint(`${URL_DOMAIN}/matches`);
export const match = new Endpoint(`${matches.url}/{id}`);
export const approvedMatches = new Endpoint(`${matches.url}/approval`);
export const maxDayMatches = new Endpoint(`${matches.url}/max-day`);
export const activePlayers = new Endpoint(`${match.url}/active-players`);
export const startMatch = new Endpoint(`${match.url}/start`);
export const finishMatch = new Endpoint(`${match.url}/finish`);
export const matchConflict = new Endpoint(`${finishMatch.url}/resolve-conflict`);
export const finishMatchWalkover = new Endpoint(`${finishMatch.url}/{id}`);
export const walkoverConflict = new Endpoint(`${finishMatchWalkover.url}/resolve-conflict`);
export const goals = new Endpoint(`${matches.url}/goals`);
export const matchPdf = new Endpoint(`${match.url}/pdf`);
export const matchGoals = new Endpoint(`${match.url}/goals`);
export const matchGoal = new Endpoint(`${matchGoals.url}/{id}`);
export const matchCards = new Endpoint(`${match.url}/cards`);
export const matchCard = new Endpoint(`${matchCards.url}/{id}`);
export const matchTeamGoals = new Endpoint(`${match.url}/teams/{id}/goals`);
export const matchTeamCards = new Endpoint(`${match.url}/teams/{id}/cards`);
export const matchAssignment = new Endpoint(`${match.url}/assignment`);
export const matchReject = new Endpoint(`${match.url}/reject`);
export const matchApprove = new Endpoint(`${match.url}/approve`);
export const refereeAssignment = new Endpoint(`${match.url}/referee-assignment`);
export const availableCards = new Endpoint(`${match.url}/players/{id}/available-cards`);
export const prevMatchHalf = new Endpoint(`${match.url}/prev-period`);
export const nextMatchHalf = new Endpoint(`${match.url}/next-period`);

// TEAMS DOMAIN
export const teams = new Endpoint(`${URL_DOMAIN}/teams`);
export const team = new Endpoint(`${teams.url}/{id}`);
export const teamsReferees = new Endpoint(`${teams.url}/referees`);
export const teamReferees = new Endpoint(`${team.url}/referees`);
export const teamLeader = new Endpoint(`${teamsReferees.url}/{id}/team-leader`);

// REGISTRATION REQUESTS DOMAIN
export const registrationRequests = new Endpoint(`${URL_DOMAIN}/registration-requests`);
export const registrationRequest = new Endpoint(`${registrationRequests.url}/{id}`);

// CONFLICTS DOMAIN
export const conflicts = new Endpoint(`${URL_DOMAIN}/conflicts`);
export const conflictsSize = new Endpoint(`${conflicts.url}/size`);

// GAMESYSTEMS DOMAIN
export const gameSystems = new Endpoint(`${URL_DOMAIN}/game-systems`);

// TOURNAMENT DOMAIN
export const tournament = new Endpoint(`${URL_DOMAIN}/tournaments/{id}`);
