interface ErrorJsonResponse {
  status: string;
  message: string;
  validationError?: Record<string, string>;
}

export class APIError extends Error {
  public status: number;

  public validationErrors;

  constructor(responseStatus: number, json: ErrorJsonResponse) {
    super(json.message);
    this.name = 'BackendError';
    this.status = responseStatus;
    this.validationErrors = json.validationError;
  }
}
